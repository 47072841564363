export const useCss = () => {
  /* get an object with a css key and a value (string or number) and returns the right css value (it also handle falsy values by removing them)
   * e.g. { width: 100, maxWidth: '100%', heigth: '50', maxHeight: '10rem', margin: '' } returns ->
   *      { width: '100px', maxWidth: '100%', heigth: '50px', maxHeight: '10rem' }
   */
  const formatStyleSizeToPx = (sizes: { [key: string]: string | number }) => {
    return Object.keys(sizes).reduce((prev, key) => {
      const value = sizes[key]

      return {
        ...prev,
        ...(value
          ? {
              [key]: isNaN(value as number) ? value : `${value}px`
            }
          : {})
      }
    }, {})
  }

  return { formatStyleSizeToPx }
}
